(function ( $ ) {
     $.fn.renderMap = function (options) {
         var settings = $.extend({
            someOption: ""
        }, options);

        var objLink    = $(this);
        var lat        = parseFloat($(this).attr('data-lat'));
        var lng        = parseFloat($(this).attr('data-lng'));
        var zoom       = parseInt($(this).attr('data-zoom'));

        function _init() {
            objLink.each(function (index) {
                _renderMap($(this));
            });
        }

        function _renderMap(o){
            $.getScript( url+"/scripts/libs/leaflet.js", function( data, textStatus, jqxhr ) {
                var LeafIcon = L.Icon.extend({
                    options: {
                        iconSize:     [28, 30],
                        iconAnchor:   [14, 30]
                    }
                });
                var icons  = new LeafIcon({iconUrl: url+'/img/mappin.svg'});


                var mbAttr = '',
                    //mbUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoicm9jZXNtYW5pYyIsImEiOiJjam5hb2NzazQyZWxoM2tydWd0OHN5OThkIn0.2kz8ZH1-HH-TkOh1anDHDA';
                    mbUrl = 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png';
                    //mbUrl = 'https://api.maptiler.com/maps/streets/tiles.json?key=29OKBCz4SMpd2ckatRps';
                //var grayscale   = L.tileLayer(mbUrl, {id: 'mapbox/light-v9', attribution: mbAttr});
                var grayscale = L.tileLayer(mbUrl, {
                	maxZoom: 20,
                	attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                });
                var map = L.map('map', {
                    center: [lat, lng],
                    zoom: zoom,
                    zoomControl: true,
                    layers: [grayscale],
                    scrollWheelZoom: false
                });

                map.zoomControl.setPosition('topright');

                L.marker([lat, lng], {icon: icons}).addTo(map);
            });
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

$('#map').renderMap();
